import React from "react"
import { Box, Button, SimpleGrid } from "@chakra-ui/react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Card,
  IntroText,
  TwoCols,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { SecondaryPagesHero } from "../../../components/elements"
import { Helmet } from "react-helmet"

const Podcasts = ({ data, pageContext }) => {
  const page = data.datoCmsCancerPodcast

  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>{page.title} | WVU Cancer Institute</title>
      </Helmet>

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Main>
        <Section>
          <Container>
            <IntroText>{page.introContent}</IntroText>
            <TwoCols content={`${page.contentNode.childMarkdownRemark.html}`} />

            <SimpleGrid spacing={6} columns={[1, 1, 2]} mt={[6, 6, 12]}>
              {data.allDatoCmsCancerPodcastShow.edges.map(({ node: show }) => (
                <Box key={show.id}>
                  <Card>
                    <GatsbyImage image={show.heroImage.gatsbyImageData} />
                    <Box p={[3, 3, 6]} sx={{ a: { textDecoration: "none" } }}>
                      <Box as="h3">{show.title}</Box>
                      <Link to={show.slug}>
                        <Button as="span">Learn More</Button>
                      </Link>
                    </Box>
                  </Card>
                </Box>
              ))}
            </SimpleGrid>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Podcasts

export const query = graphql`
  {
    datoCmsCancerPodcast {
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      introContent
      introContentNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      title
      seo {
        title
        description
      }
    }
    allDatoCmsCancerPodcastShow {
      edges {
        node {
          id
          slug
          title
          heroImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`
